<template>
  <base-material-card class="mt-12">
    <template #heading>
      <div class="subtitle-1 font-weight-light">
        {{ $t('customers.address.address') }}
      </div>
    </template>
    <v-card-text>
      <v-row>
        <v-col cols="6" sm="6" md="6">
          <v-text-field
            v-model="address.id"
            label="ID"
            dense
            disabled
            @keydown.esc="onCancel"
          />
        </v-col>

        <v-col cols="6" sm="6" md="6">
          <v-switch
            v-model="address.active"
            :label="$t('customers.address.active')"
          />
        </v-col>

        <v-col cols="6" sm="6" md="4">
          <validation-provider
            v-slot="{ errors }"
            rules="required|min:1"
            :name="$t('customers.address.name')"
          >
            <v-text-field
              v-model="address.name"
              :label="$t('customers.address.name')"
              dense
              :disabled="!address.active"
              :error-messages="errors"
              @keydown.esc="onCancel"
            />
          </validation-provider>
        </v-col>

        <v-col cols="6" sm="6" md="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required|min:1"
            :name="$t('customers.address.address')"
          >
            <v-text-field
              v-model="address.address"
              :label="$t('customers.address.address')"
              dense
              :disabled="!address.active"
              :error-messages="errors"
              @keydown.esc="onCancel"
            />
          </validation-provider>
        </v-col>
        <v-col cols="6" sm="6" md="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required|min:1"
            :name="$t('customers.address.city')"
          >
            <v-text-field
              v-model="address.city"
              :label="$t('customers.address.city')"
              dense
              :disabled="!address.active"
              :error-messages="errors"
              @keydown.esc="onCancel"
            />
          </validation-provider>
        </v-col>
        <v-col cols="6" sm="6" md="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required|min:1"
            :name="$t('customers.address.post-code')"
          >
            <v-text-field
              v-model="address.zip"
              :label="$t('customers.address.post-code')"
              dense
              :disabled="!address.active"
              :error-messages="errors"
              @keydown.esc="onCancel"
            />
          </validation-provider>
        </v-col>
        <v-col cols="6" sm="6" md="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required|min:1"
            :name="$t('customers.address.country')"
          >
            <v-text-field
              v-model="address.country"
              :label="$t('customers.address.country')"
              dense
              :disabled="!address.active"
              :error-messages="errors"
              @keydown.esc="onCancel"
            />
          </validation-provider>
        </v-col>

        <v-col cols="6" sm="6" md="4">
          <v-text-field
            v-model="address.contact"
            :label="$t('customers.contact')"
            dense
            :disabled="!address.active"
            @keydown.esc="onCancel"
          />
        </v-col>
        <v-col cols="6" sm="6" md="4">
          <v-text-field
            v-model="address.phone"
            :label="$t('customers.phone')"
            dense
            :disabled="!address.active"
            @keydown.esc="onCancel"
          />
        </v-col>
        <v-col cols="6" sm="6" md="4">
          <validation-provider
            v-slot="{ errors }"
            rules="email"
            :name="$t('customers.email')"
          >
            <v-text-field
              v-model="address.email"
              :label="$t('customers.email')"
              dense
              :disabled="!address.active"
              :error-messages="errors"
              @keydown.esc="onCancel"
            />
          </validation-provider>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-select
            v-model="address.types"
            :items="address_types"
            :menu-props="{ maxHeight: '400' }"
            :hint="$t('select-hint')"
            multiple
            :label="$t('customers.address-types')"
            persistent-hint
            dense
            :disabled="!address.active"
            @keydown.esc="onCancel"
          />
        </v-col>
      </v-row>

      <v-btn
        v-if="isNew"
        class="mx-2"
        fab
        dark
        color="accent"
        @click="deleteAddress(address)"
      >
        <v-icon dark>
          mdi-delete
        </v-icon>
      </v-btn>
    </v-card-text>
  </base-material-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    isNew: {
      type: Boolean,
      required: true
    }
  },
  data: function() {
    return {
      address: this.value,
      address_types: ['Delivery', 'Payment', 'Office']
    };
  },
  computed: {},
  watch: {
    value: {
      handler: function(val) {
        this.address = val;
      },
      deep: true
    },
    address: {
      handler: function(val) {
        this.$emit('input', val);
      },
      deep: true
    }
  },
  mounted() {},
  methods: {
    deleteAddress(address) {
      console.log('deleteAddress ', address);
      this.$emit('delete', address);
    },
    onCancel() {
      this.$emit('cancel');
    }
  }
};
</script>

<style></style>
